import { App } from "@capacitor/app";
import { Device } from "@capacitor/device";
// import { Camera, CameraResultType } from "@capacitor/camera";
import { defineCustomElements } from "@ionic/pwa-elements/loader";
// import { v4 as uuid } from "uuid";
import { EventSource as EventSourceIOS } from "capacitor-eventsource";
import { Elm } from "./Main.elm";
import initCustomElement from "./js/aw-image";
import Db from "./js/db";
import Http from "./js/http";
import jsonData from "../versions.json";

let PlatformVar = "";
let BaseUri = "";

async function loadBaseUri() {
    const baseUri = await Db.loadBaseUri();

    if (!baseUri) {
        Db.saveBaseUri("https://smart.awenko.de");
        BaseUri = "https://smart.awenko.de";
    } else {
        BaseUri = baseUri;
    }
}

function getLanguage() {
    return Device.getLanguageCode().then((result) => result.value.substring(0, 2).toUpperCase());
}

async function getPlatform() {
    const result = await Device.getInfo();
    PlatformVar = result.platform;

    return {
        platform: result.platform,
        version: jsonData[`${result.platform}-version`],
    };
}

function getRandomInts(n) {
    const crypto = window.crypto || window.msCrypto;
    const randInts = new Uint32Array(n);

    crypto.getRandomValues(randInts);

    return Array.from(randInts);
}

async function initFlags() {
    const language = await getLanguage();
    const platform = await getPlatform();

    const translationUrls = {
        de: "./assets/locales/de/translations.json",
        en: "./assets/locales/en/translations.json",
    };
    const network = window.navigator.onLine ? "ONLINE" : "OFFLINE";
    const randomInts = getRandomInts(5);

    return {
        seed: randomInts[0],
        seedExtension: randomInts.slice(1),
        language: language,
        translationUrls: translationUrls,
        network: network,
        baseUri: BaseUri,
        platform: platform,
        apiVersion: jsonData["api-version"],
    };
}

// function mimeTypeToFileExtension(mimeType) {
//     switch (mimeType) {
//     case "image/jpeg":
//         return ".jpg";
//     case "image/png":
//         return ".png";
//     case "image/gif":
//         return ".gif";
//     default:
//         return "";
//     }
// }

// function takePhoto() {
//     return Camera
//         .getPhoto({
//             quality: 100,
//             allowEditing: false,
//             saveToGallery: false,
//             resultType: CameraResultType.Uri,
//         })
//         .then((cameraPhoto) => fetch(cameraPhoto.webPath))
//         .then((response) => response.blob());
// }

// function selectImage() {
//     return new Promise((resolve) => {
//         const input = document.createElement("input");
//
//         input.type = "file";
//         input.accept = "image/*";
//         input.onchange = (event) => {
//             const file = event.target.files[0];
//             const reader = new FileReader();
//
//             reader.onload = async (readerEvent) => {
//                 const arrayBuffer = readerEvent.target.result;
//                 const blob = new Blob([arrayBuffer], { type: file.type });
//
//                 resolve(blob);
//             };
//
//             reader.readAsArrayBuffer(file);
//         };
//
//         input.click();
//     });
// }

function uploadFile(filename, blob) {
    const formData = new FormData();

    formData.append("file", blob, filename);

    const url = `${BaseUri}/files/`;

    const session = Db.loadSession();

    const options = {
        method: "POST",
        headers: {
            "tenant-id": session.tenantId,
            "user-id": session.userId,
        },
        credentials: "include",
        body: formData,
    };

    return fetch(url, options);
}

function downloadFile(filename) {
    const url = `${BaseUri}/files/${filename}`;

    const session = Db.loadSession();

    const options = {
        headers: {
            "tenant-id": session.tenantId,
            "user-id": session.userId,
        },
        credentials: "include",
    };

    return fetch(url, options)
        .then((response) => response.blob());
}

function initEnvironment(outbox, inbox) {
    App.addListener("appStateChange", (state) => {
        inbox.send({
            type: "CHANGED_APP_STATE",
            state: state.isActive ? "ACTIVE" : "INACTIVE",
        });
    });

    window.addEventListener("online", () => {
        inbox.send({ type: "CHANGED_NETWORK_STATE", state: "ONLINE" });
    }, false);

    window.addEventListener("offline", () => {
        inbox.send({ type: "CHANGED_NETWORK_STATE", state: "OFFLINE" });
    });

    outbox.subscribe(async (msg) => {
        switch (msg.type) {
        case "LOAD_SESSION": {
            let session = await Db.loadSession();
            Http
                .sessionCheck(`${BaseUri}/session-check`)
                .then(async (response) => {
                    if (response.status !== 200) {
                        if (!session) {
                            await Db.deleteSession();
                            session = undefined;
                        } else {
                            inbox.send({
                                type: "SESSION_EXPIRED",
                            });
                        }
                    }
                });
            inbox.send({
                type: "RECEIVED_SESSION",
                session: session,
            });
            // ToDo csrf token send per Header instead of cookie

            break;
        }
        case "SAVE_SESSION": {
            await Db.saveSession(msg.session);

            inbox.send({ type: "SAVED_SESSION", session: msg.session });
            break;
        }
        case "DELETE_SESSION": {
            await Db.deleteSession();
            break;
        }
        default:
            break;
        }
    });
}

function initLogin(outbox, inbox) {
    outbox.subscribe(async (msg) => {
        switch (msg.type) {
        case "LOGIN": {
            const data = {
                tenantName: msg.tenantName,
                username: msg.username,
                password: msg.password,
            };
            Http
                .login(`${BaseUri}/login`, data)
                .then((response) => {
                    if (response.status === 200) {
                        inbox.send({
                            type: "LOGIN_SUCCEEDED",
                            tenantId: response.data.tenantId,
                            userId: response.data.userId,
                        });
                    } else if (response.status === 401) {
                        inbox.send({
                            type: "LOGIN_FAILED",
                            msg: "LOGIN_INVALID",
                        });
                    } else {
                        inbox.send({
                            type: "LOGIN_FAILED",
                            msg: "SERVER_ERROR",
                        });
                    }
                })
                .catch(() => {
                    inbox.send({
                        type: "LOGIN_FAILED",
                        msg: "TIMEOUT",
                    });
                });
            break;
        }
        case "GET_CURRENT_URL": {
            inbox.send({ type: "CURRENT_URL", url: window.location.href });
            break;
        }
        case "SAVE_BASE_URI": {
            Db.saveBaseUri(msg.baseUri).then(() => {
                BaseUri = msg.baseUri;
                Http.sessionCheck(`${BaseUri}/session-check`);
                inbox.send({ type: "SAVED_BASE_URI" });
            });
            break;
        }
        default:
            break;
        }
    });
}

function initRelogin(outbox, inbox) {
    outbox.subscribe(async (msg) => {
        switch (msg.type) {
        case "LOGIN": {
            const data = {
                tenantName: msg.tenantName,
                username: msg.username,
                password: msg.password,
            };
            Http
                .login(`${BaseUri}/login`, data)
                .then((response) => {
                    if (response.status === 200) {
                        inbox.send({
                            type: "LOGIN_SUCCEEDED",
                            tenantId: response.data.tenantId,
                            userId: response.data.userId,
                        });
                    } else if (response.status === 401) {
                        inbox.send({
                            type: "LOGIN_FAILED",
                            msg: "LOGIN_INVALID",
                        });
                    } else {
                        inbox.send({
                            type: "LOGIN_FAILED",
                            msg: "SERVER_ERROR",
                        });
                    }
                })
                .catch(() => {
                    inbox.send({
                        type: "LOGIN_FAILED",
                        msg: "TIMEOUT",
                    });
                });
            break;
        }
        case "LOAD_TENANT": {
            const tenant = await Db.loadTenantData();
            inbox.send({
                type: "GOT_TENANT",
                tenant: tenant.tenant,
            });
            break;
        }
        default:
            break;
        }
    });
}

function initEventStream(outbox, inbox) {
    let eventStream;

    function createUri(parameters) {
        const searchParameters = new URLSearchParams();

        if (parameters && parameters.userEventId) {
            searchParameters.append("userEventId", parameters.userEventId);
        }

        if (parameters && parameters.organisationUnitEventId) {
            searchParameters.append("organisationUnitEventId", parameters.organisationUnitEventId);
        }

        if (parameters && parameters.planEventId) {
            searchParameters.append("planEventId", parameters.planEventId);
        }

        if (parameters && parameters.measureEventId) {
            searchParameters.append("measureEventId", parameters.measureEventId);
        }

        if (parameters && parameters.auditEventId) {
            searchParameters.append("auditEventId", parameters.auditEventId);
        }

        if (parameters && parameters.tenantEventId) {
            searchParameters.append("tenantEventId", parameters.tenantEventId);
        }

        if (parameters && parameters.checkpointDataEventId) {
            searchParameters.append("checkpointGroupEventId", parameters.checkpointDataEventId);
        }

        if (parameters && parameters.propertyEventId) {
            searchParameters.append("propertyEventId", parameters.propertyEventId);
        }

        searchParameters.append("apiVersion", jsonData["api-version"]);

        return `${BaseUri}/sync/?${searchParameters.toString()}`;
    }

    function connect(parameters) {
        if (PlatformVar === "ios") {
            EventSourceIOS.configure({
                url: createUri(parameters),
                reconnectTime: 1000,
                maxReconnectTime: 60000,
                idleTimeout: 30000,
            })
                .then(() => {
                    EventSourceIOS.open()
                        .then(() => {
                        });
                });

            EventSourceIOS.addListener("open", () => {
                inbox.send({
                    type: "STATUS",
                    state: { status: "CONNECTED" },
                });
            });

            EventSourceIOS.addListener("readyStateChanged", () => {
                inbox.send({
                    type: "STATUS",
                    state: { status: "CONNECTED" },
                });
            });

            EventSourceIOS.addListener("error", (error) => {
                EventSourceIOS.close();
                inbox.send({
                    type: "STATUS",
                    state: { status: "ERROR", error: JSON.stringify(error) },
                });
            });

            EventSourceIOS.addListener("message", (messageResult) => {
                if (messageResult.type) {
                    switch (messageResult.type) {
                    case "user-status":
                        inbox.send({
                            type: "USER_STATUS",
                            status: messageResult.message,
                        });
                        break;
                    case "user-event":
                        inbox.send({
                            type: "USER_EVENT",
                            event: JSON.parse(messageResult.message),
                        });
                        break;
                    case "organisation-unit-status":
                        inbox.send({
                            type: "ORGANISATION_UNIT_STATUS",
                            status: messageResult.message,
                        });
                        break;
                    case "organisation-unit-event":
                        inbox.send({
                            type: "ORGANISATION_UNIT_EVENT",
                            event: JSON.parse(messageResult.message),
                        });
                        break;
                    case "plan-status":
                        inbox.send({
                            type: "PLAN_STATUS",
                            status: messageResult.message,
                        });
                        break;
                    case "plan-event":
                        inbox.send({
                            type: "PLAN_EVENT",
                            event: JSON.parse(messageResult.message),
                        });
                        break;
                    case "audit-status":
                        inbox.send({
                            type: "AUDIT_STATUS",
                            status: messageResult.message,
                        });
                        break;
                    case "audit-event":
                        inbox.send({
                            type: "AUDIT_EVENT",
                            event: JSON.parse(messageResult.message),
                        });
                        break;
                    case "measure-status":
                        inbox.send({
                            type: "MEASURE_STATUS",
                            status: messageResult.message,
                        });
                        break;
                    case "measure-event":
                        inbox.send({
                            type: "MEASURE_EVENT",
                            event: JSON.parse(messageResult.message),
                        });
                        break;
                    case "tenant-status":
                        inbox.send({
                            type: "TENANT_STATUS",
                            status: messageResult.message,
                        });
                        break;
                    case "tenant-event":
                        inbox.send({
                            type: "TENANT_EVENT",
                            event: JSON.parse(messageResult.message),
                        });
                        break;
                    case "checkpoint-group-status":
                        inbox.send({
                            type: "CHECKPOINT_DATA_STATUS",
                            status: messageResult.message,
                        });
                        break;
                    case "checkpoint-group-event":
                        inbox.send({
                            type: "CHECKPOINT_DATA_EVENT",
                            event: JSON.parse(messageResult.message),
                        });
                        break;
                    case "property-status":
                        inbox.send({
                            type: "PROPERTY_STATUS",
                            status: messageResult.message,
                        });
                        break;
                    case "property-event":
                        inbox.send({
                            type: "PROPERTY_EVENT",
                            event: JSON.parse(messageResult.message),
                        });
                        break;
                    default:
                        break;
                    }
                }
            });
        } else {
            eventStream = new EventSource(createUri(parameters), { withCredentials: true });

            eventStream.onopen = () => {
                inbox.send({ type: "STATUS", state: { status: "CONNECTED" } });
            };

            eventStream.onerror = (error) => {
                if (eventStream) {
                    eventStream.close();
                    eventStream = null;

                    inbox.send({ type: "STATUS", state: { status: "ERROR", error: JSON.stringify(error) } });
                }
            };

            eventStream.addEventListener("user-status", (event) => {
                inbox.send({ type: "USER_STATUS", status: event.data });
            });

            eventStream.addEventListener("user-event", (event) => {
                inbox.send({ type: "USER_EVENT", event: JSON.parse(event.data) });
            });

            eventStream.addEventListener("organisation-unit-status", (event) => {
                inbox.send({ type: "ORGANISATION_UNIT_STATUS", status: event.data });
            });

            eventStream.addEventListener("organisation-unit-event", (event) => {
                inbox.send({ type: "ORGANISATION_UNIT_EVENT", event: JSON.parse(event.data) });
            });

            eventStream.addEventListener("plan-status", (event) => {
                inbox.send({ type: "PLAN_STATUS", status: event.data });
            });

            eventStream.addEventListener("plan-event", (event) => {
                inbox.send({ type: "PLAN_EVENT", event: JSON.parse(event.data) });
            });

            eventStream.addEventListener("audit-status", (event) => {
                inbox.send({ type: "AUDIT_STATUS", status: event.data });
            });

            eventStream.addEventListener("audit-event", (event) => {
                inbox.send({ type: "AUDIT_EVENT", event: JSON.parse(event.data) });
            });

            eventStream.addEventListener("measure-status", (event) => {
                inbox.send({ type: "MEASURE_STATUS", status: event.data });
            });

            eventStream.addEventListener("measure-event", (event) => {
                inbox.send({ type: "MEASURE_EVENT", event: JSON.parse(event.data) });
            });

            eventStream.addEventListener("tenant-status", (event) => {
                inbox.send({ type: "TENANT_STATUS", status: event.data });
            });

            eventStream.addEventListener("tenant-event", (event) => {
                inbox.send({ type: "TENANT_EVENT", event: JSON.parse(event.data) });
            });

            eventStream.addEventListener("checkpoint-group-status", (event) => {
                inbox.send({ type: "CHECKPOINT_DATA_STATUS", status: event.data });
            });

            eventStream.addEventListener("checkpoint-group-event", (event) => {
                inbox.send({ type: "CHECKPOINT_DATA_EVENT", event: JSON.parse(event.data) });
            });

            eventStream.addEventListener("property-status", (event) => {
                inbox.send({ type: "PROPERTY_STATUS", status: event.data });
            });

            eventStream.addEventListener("property-event", (event) => {
                inbox.send({ type: "PROPERTY_EVENT", event: JSON.parse(event.data) });
            });
        }
    }

    outbox.subscribe(async (msg) => {
        switch (msg.type) {
        case "CONNECT": {
            connect(msg.parameters);
            break;
        }
        case "DISCONNECT": {
            if (PlatformVar === "ios") {
                await EventSourceIOS.close();
            } else if (eventStream) {
                eventStream.close();
                eventStream = null;
            }
            inbox.send({ type: "STATUS", state: { status: "DISCONNECTED" } });
            break;
        }
        default:
            break;
        }
    });
}

function initFileStore(outbox, inbox) {
    outbox.subscribe(async (msg) => {
        switch (msg.type) {
        case "LOAD_FILES": {
            const files = await Db.loadFiles();

            inbox.send({ type: "LOADED_FILES", files: files });
            break;
        }
        case "SAVE_FILES": {
            await Db.saveFiles(msg.files);

            inbox.send({ type: "SAVED_FILES" });
            break;
        }
        case "SAVE_MODIFICATIONS": {
            await Db.saveFileModifications(msg.modifications);

            inbox.send({ type: "SAVED_MODIFICATIONS" });
            break;
        }
        case "UPLOAD_FILE": {
            const { file } = msg;
            const { filename } = file;

            Db.loadBlob(filename)
                .then((blob) => uploadFile(filename, blob))
                .then(() => {
                    file.status = "SYNCHRONIZED";

                    return Db.saveFile(file);
                })
                .then(() => inbox.send({ type: "UPLOADED_FILE", file: msg.file }));
            break;
        }
        case "DOWNLOAD_FILE": {
            const { file } = msg;
            const { filename } = file;

            downloadFile(filename)
                .then((blob) => {
                    file.status = "SYNCHRONIZED";

                    return Db.saveFileAndBlob(file, blob);
                })
                .then(() => inbox.send({ type: "DOWNLOADED_FILE", file: msg.file }));
            break;
        }
        case "DELETE_FILE": {
            Db.deleteFile(msg.filename)
                .then(() => inbox.send({ type: "DELETED_FILE", file: msg.filename }));
            break;
        }
        default:
            break;
        }
    });
}

function initUserStore(outbox, inbox) {
    outbox.subscribe(async (msg) => {
        switch (msg.type) {
        case "LOAD_DATA": {
            const userData = await Db.loadUserData();

            inbox.send({ type: "LOADED_DATA", data: userData });
            break;
        }
        case "SAVE_USERS": {
            await Db.saveUsers(msg.users);

            inbox.send({ type: "SAVED_USERS" });
            break;
        }
        case "SAVE_MODIFICATIONS": {
            await Db.saveUserModifications(msg.modifications);

            inbox.send({ type: "SAVED_MODIFICATIONS" });
            break;
        }
        case "SAVE_COMMANDS_AND_EVENTS": {
            await Db.saveUserCommandsAndEvents(msg.commands, msg.events);

            inbox.send({ type: "SAVED_COMMANDS_AND_EVENTS" });
            break;
        }
        case "REMOVE_COMMAND": {
            await Db.removeUserCommand(msg.commandId);

            inbox.send({ type: "REMOVED_COMMAND" });
            break;
        }
        case "REMOVE_COMMAND_AND_EVENT": {
            await Db.removeUserCommandAndEvent(msg.commandId, msg.userId, msg.eventVersion);

            inbox.send({
                type: "REMOVED_COMMAND_AND_EVENT",
                commandId: msg.commandId,
                userId: msg.userId,
                eventVersion: msg.eventVersion,
            });
            break;
        }
        default:
            break;
        }
    });
}

function initHttpPort(outbox, inbox) {
    outbox.subscribe(async (msg) => {
        switch (msg.type) {
        case "SEND_POST": {
            Http
                .post(msg.url, msg.data, msg.headerArray)
                .then((response) => {
                    let data;
                    try {
                        data = JSON.parse(response.data);
                    } catch (error) {
                        data = response.data;
                    }

                    inbox.send({
                        type: "GOT_RESPONSE",
                        resultType: msg.resultType,
                        command: msg.data,
                        status: response.status,
                        data: data,
                    });
                })
                .catch(() => {
                    inbox.send({
                        type: "GOT_ERROR_RESPONSE",
                        resultType: msg.resultType,
                    });
                });
            break;
        }
        case "SEND_GET": {
            Http
                .get(msg.url, msg.headerArray)
                .then((response) => {
                    let data;
                    try {
                        data = JSON.parse(response.data);
                    } catch (error) {
                        data = response.data;
                    }

                    inbox.send({
                        type: "GOT_RESPONSE",
                        resultType: msg.resultType,
                        status: response.status,
                        data: data,
                    });
                })
                .catch(() => {
                    inbox.send({
                        type: "GOT_ERROR_RESPONSE",
                        resultType: msg.resultType,
                    });
                });
            break;
        }
        default:
            break;
        }
    });
}

function initOrganisationUnitStore(outbox, inbox) {
    outbox.subscribe(async (msg) => {
        switch (msg.type) {
        case "LOAD_DATA": {
            const organisationUnitData = await Db.loadOrganisationUnitData();

            inbox.send({ type: "LOADED_DATA", data: organisationUnitData });
            break;
        }
        case "SAVE_ORGANISATION_UNITS": {
            await Db.saveOrganisationUnits(msg.organisationUnits);

            inbox.send({ type: "SAVED_ORGANISATION_UNITS" });
            break;
        }
        case "SAVE_MODIFICATIONS": {
            await Db.saveOrganisationUnitModifications(msg.modifications);

            inbox.send({ type: "SAVED_MODIFICATIONS" });
            break;
        }
        case "SAVE_COMMANDS_AND_EVENTS": {
            await Db.saveOrganisationUnitCommandsAndEvents(msg.commands, msg.events);

            inbox.send({ type: "SAVED_COMMANDS_AND_EVENTS" });
            break;
        }
        case "REMOVE_COMMAND": {
            await Db.removeOrganisationUnitCommand(msg.commandId);

            inbox.send({ type: "REMOVED_COMMAND" });
            break;
        }
        case "REMOVE_COMMAND_AND_EVENT": {
            await Db.removeOrganisationUnitCommandAndEvent(msg.commandId, msg.eventVersion);

            inbox.send({
                type: "REMOVED_COMMAND_AND_EVENT",
                commandId: msg.commandId,
                eventVersion: msg.eventVersion,
            });
            break;
        }
        default:
            break;
        }
    });
}

function initPlanStore(outbox, inbox) {
    outbox.subscribe(async (msg) => {
        switch (msg.type) {
        case "LOAD_DATA": {
            const planData = await Db.loadPlanData();

            inbox.send({ type: "LOADED_DATA", data: planData });
            break;
        }
        case "SAVE_PLANS": {
            await Db.savePlans(msg.plans);

            inbox.send({ type: "SAVED_PLANS" });
            break;
        }
        case "SAVE_MODIFICATIONS": {
            await Db.savePlanModifications(msg.modifications);

            inbox.send({ type: "SAVED_MODIFICATIONS" });
            break;
        }
        case "SAVE_COMMANDS_AND_EVENTS": {
            await Db.savePlanCommandsAndEvents(msg.commands, msg.events);

            inbox.send({ type: "SAVED_COMMANDS_AND_EVENTS" });
            break;
        }
        case "REMOVE_COMMAND": {
            await Db.removePlanCommand(msg.commandId);

            inbox.send({ type: "REMOVED_COMMAND" });
            break;
        }
        case "REMOVE_COMMAND_AND_EVENT": {
            await Db.removePlanCommandAndEvent(msg.commandId, msg.planId, msg.eventVersion);

            inbox.send({
                type: "REMOVED_COMMAND_AND_EVENT",
                commandId: msg.commandId,
                planId: msg.planId,
                eventVersion: msg.eventVersion,
            });
            break;
        }
        default:
            break;
        }
    });
}

function initAuditStore(outbox, inbox) {
    outbox.subscribe(async (msg) => {
        switch (msg.type) {
        case "LOAD_DATA": {
            const auditData = await Db.loadAuditData();

            inbox.send({ type: "LOADED_DATA", data: auditData });
            break;
        }
        case "SAVE_AUDITS": {
            await Db.saveAudits(msg.audits);

            inbox.send({ type: "SAVED_AUDITS" });
            break;
        }
        case "SAVE_MODIFICATIONS": {
            await Db.saveAuditModifications(msg.modifications);

            inbox.send({ type: "SAVED_MODIFICATIONS" });
            break;
        }
        case "SAVE_COMMANDS_AND_EVENTS": {
            await Db.saveAuditCommandsAndEvents(msg.commands, msg.events);

            inbox.send({ type: "SAVED_COMMANDS_AND_EVENTS" });
            break;
        }
        case "REMOVE_COMMAND": {
            await Db.removeAuditCommand(msg.commandId);

            inbox.send({ type: "REMOVED_COMMAND" });
            break;
        }
        case "REMOVE_COMMAND_AND_EVENT": {
            await Db.removeAuditCommandAndEvent(msg.commandId, msg.auditId, msg.eventVersion);

            inbox.send({
                type: "REMOVED_COMMAND_AND_EVENT",
                commandId: msg.commandId,
                auditId: msg.auditId,
                eventVersion: msg.eventVersion,
            });
            break;
        }
        default:
            break;
        }
    });
}

function initMeasureStore(outbox, inbox) {
    outbox.subscribe(async (msg) => {
        switch (msg.type) {
        case "LOAD_DATA": {
            const measureData = await Db.loadMeasureData();

            inbox.send({ type: "LOADED_DATA", data: measureData });
            break;
        }
        case "SAVE_MEASURES": {
            await Db.saveMeasures(msg.measures);

            inbox.send({ type: "SAVED_MEASURES" });
            break;
        }
        case "SAVE_MODIFICATIONS": {
            await Db.saveMeasureModifications(msg.modifications);

            inbox.send({ type: "SAVED_MODIFICATIONS" });
            break;
        }
        case "SAVE_COMMANDS_AND_EVENTS": {
            await Db.saveMeasureCommandsAndEvents(msg.commands, msg.events);

            inbox.send({ type: "SAVED_COMMANDS_AND_EVENTS" });
            break;
        }
        case "REMOVE_COMMAND": {
            await Db.removeMeasureCommand(msg.commandId);

            inbox.send({ type: "REMOVED_COMMAND" });
            break;
        }
        case "REMOVE_COMMAND_AND_EVENT": {
            await Db.removeMeasureCommandAndEvent(msg.commandId, msg.measureId, msg.eventVersion);

            inbox.send({
                type: "REMOVED_COMMAND_AND_EVENT",
                commandId: msg.commandId,
                measureId: msg.measureId,
                eventVersion: msg.eventVersion,
            });
            break;
        }
        default:
            break;
        }
    });
}

// function initMeasureImageEditor(outbox, inbox) {
//     outbox.subscribe(async (msg) => {
//         switch (msg.type) {
//         case "TAKE_PHOTO": {
//             const blob = await takePhoto();
//             const filename = `${uuid().toString()}${mimeTypeToFileExtension(blob.type)}`;
//
//             await Db.saveBlob(filename, blob);
//
//             inbox.send({ type: "PHOTO_TAKEN", filename: filename });
//             break;
//         }
//         case "SELECT_IMAGE": {
//             const blob = await selectImage();
//             const filename = `${uuid().toString()}${mimeTypeToFileExtension(blob.type)}`;
//
//             await Db.saveBlob(filename, blob);
//
//             inbox.send({ type: "IMAGE_SELECTED", filename: filename });
//             break;
//         }
//         default:
//             break;
//         }
//     });
// }

function initTenantStore(outbox, inbox) {
    outbox.subscribe(async (msg) => {
        switch (msg.type) {
        case "LOAD_DATA": {
            const tenantData = await Db.loadTenantData();

            inbox.send({ type: "LOADED_DATA", data: tenantData });
            break;
        }
        case "SAVE_TENANT": {
            await Db.saveTenant(msg.tenant);

            inbox.send({ type: "SAVED_TENANT" });
            break;
        }
        case "SAVE_MODIFICATIONS": {
            await Db.saveTenantModifications(msg.modifications);

            inbox.send({ type: "SAVED_MODIFICATIONS" });
            break;
        }
        case "SAVE_COMMANDS_AND_EVENTS": {
            await Db.saveTenantCommandsAndEvents(msg.commands, msg.events);

            inbox.send({ type: "SAVED_COMMANDS_AND_EVENTS" });
            break;
        }
        case "REMOVE_COMMAND": {
            await Db.removeTenantCommand(msg.commandId);

            inbox.send({ type: "REMOVED_COMMAND" });
            break;
        }
        case "REMOVE_COMMAND_AND_EVENT": {
            await Db.removeTenantCommandAndEvent(msg.commandId, msg.tenantId, msg.eventVersion);

            inbox.send({
                type: "REMOVED_COMMAND_AND_EVENT",
                commandId: msg.commandId,
                tenantId: msg.tenantId,
                eventVersion: msg.eventVersion,
            });
            break;
        }
        default:
            break;
        }
    });
}

function initCheckpointDataStore(outbox, inbox) {
    outbox.subscribe(async (msg) => {
        switch (msg.type) {
        case "LOAD_DATA": {
            const checkpointData = await Db.loadCheckpointData();

            inbox.send({ type: "LOADED_DATA", data: checkpointData });
            break;
        }
        case "SAVE_CHECKPOINT_DATA": {
            await Db.saveCheckpointData(msg.checkpointData);

            inbox.send({ type: "SAVED_CHECKPOINT_DATA" });
            break;
        }
        case "SAVE_MODIFICATIONS": {
            await Db.saveCheckpointDataModifications(msg.modifications);

            inbox.send({ type: "SAVED_MODIFICATIONS" });
            break;
        }
        case "SAVE_COMMANDS_AND_EVENTS": {
            await Db.saveCheckpointDataCommandsAndEvents(msg.commands, msg.events);

            inbox.send({ type: "SAVED_COMMANDS_AND_EVENTS" });
            break;
        }
        case "REMOVE_COMMAND": {
            await Db.removeCheckpointDataCommand(msg.commandId);

            inbox.send({ type: "REMOVED_COMMAND" });
            break;
        }
        case "REMOVE_COMMAND_AND_EVENT": {
            await Db.removeCheckpointDataCommandAndEvent(msg.commandId, msg.eventVersion);

            inbox.send({
                type: "REMOVED_COMMAND_AND_EVENT",
                commandId: msg.commandId,
                eventVersion: msg.eventVersion,
            });
            break;
        }
        default:
            break;
        }
    });
}

function initPropertiesStore(outbox, inbox) {
    outbox.subscribe(async (msg) => {
        switch (msg.type) {
        case "LOAD_DATA": {
            const properties = await Db.loadProperties();

            inbox.send({ type: "LOADED_DATA", data: properties });
            break;
        }
        case "SAVE_PROPERTIES": {
            await Db.saveProperties(msg.properties);

            inbox.send({ type: "SAVED_PROPERTIES" });
            break;
        }
        case "SAVE_MODIFICATIONS": {
            await Db.savePropertiesModifications(msg.modifications);

            inbox.send({ type: "SAVED_MODIFICATIONS" });
            break;
        }
        case "SAVE_COMMANDS_AND_EVENTS": {
            await Db.savePropertiesCommandsAndEvents(msg.commands, msg.events);

            inbox.send({ type: "SAVED_COMMANDS_AND_EVENTS" });
            break;
        }
        case "REMOVE_COMMAND": {
            await Db.removePropertiesCommand(msg.commandId);

            inbox.send({ type: "REMOVED_COMMAND" });
            break;
        }
        case "REMOVE_COMMAND_AND_EVENT": {
            await Db.removePropertiesCommandAndEvent(msg.commandId, msg.eventVersion);

            inbox.send({
                type: "REMOVED_COMMAND_AND_EVENT",
                commandId: msg.commandId,
                eventVersion: msg.eventVersion,
            });
            break;
        }
        default:
            break;
        }
    });
}

// function initAvatarDialog(outbox, inbox) {
//     outbox.subscribe(async (msg) => {
//         switch (msg.type) {
//         case "TAKE_PHOTO": {
//             const blob = await takePhoto();
//             const filename = `${uuid()
//                 .toString()}${mimeTypeToFileExtension(blob.type)}`;
//
//             await Db.saveBlob(filename, blob);
//
//             inbox.send({
//                 type: "PHOTO_TAKEN",
//                 filename: filename,
//             });
//             break;
//         }
//         case "SELECT_IMAGE": {
//             const blob = await selectImage();
//             const filename = `${uuid()
//                 .toString()}${mimeTypeToFileExtension(blob.type)}`;
//
//             await Db.saveBlob(filename, blob);
//
//             inbox.send({
//                 type: "IMAGE_SELECTED",
//                 filename: filename,
//             });
//             break;
//         }
//         default:
//             break;
//         }
//     });
// }

// function initImageEditor(outbox, inbox) {
//     outbox.subscribe(async (msg) => {
//         switch (msg.type) {
//         case "TAKE_PHOTO": {
//             const blob = await takePhoto();
//             const filename = `${uuid().toString()}${mimeTypeToFileExtension(blob.type)}`;
//
//             await Db.saveBlob(filename, blob);
//
//             inbox.send({ type: "PHOTO_TAKEN", filename: filename });
//             break;
//         }
//         case "SELECT_IMAGE": {
//             const blob = await selectImage();
//             const filename = `${uuid().toString()}${mimeTypeToFileExtension(blob.type)}`;
//
//             await Db.saveBlob(filename, blob);
//
//             inbox.send({ type: "IMAGE_SELECTED", filename: filename });
//             break;
//         }
//         default:
//             break;
//         }
//     });
// }

function copyToClipboard(outbox, inbox) {
    function copy(value) {
        const dummy = document.createElement("textarea");

        document.body.appendChild(dummy);

        dummy.value = value;

        if (navigator.userAgent.match(/ipad|ipod|iphone/i)) {
            dummy.contentEditable = true;

            dummy.readOnly = true;

            const range = document.createRange();

            range.selectNodeContents(dummy);

            const selection = window.getSelection();

            selection.removeAllRanges();

            selection.addRange(range);

            selection.setSelectionRange(0, 999999);
        } else {
            dummy.select();
        }

        document.execCommand("copy");

        document.body.removeChild(dummy);
    }

    outbox.subscribe(async (msg) => {
        switch (msg.type) {
        case "COPY_TEXT": {
            copy(msg.text);

            inbox.send({ type: "COPIED" });
            break;
        }
        case "COPY_JSON": {
            copy(JSON.stringify(msg.json));

            inbox.send({ type: "COPIED" });
            break;
        }
        default:
            break;
        }
    });
}

function initPorts(ports) {
    initEnvironment(ports.environmentOutbox, ports.environmentInbox);
    initLogin(ports.loginOutbox, ports.loginInbox);
    initRelogin(ports.reLoginOutbox, ports.reLoginInbox);
    initEventStream(ports.eventStreamOutbox, ports.eventStreamInbox);
    initFileStore(ports.fileStoreOutbox, ports.fileStoreInbox);
    initUserStore(ports.userStoreOutbox, ports.userStoreInbox);
    initHttpPort(ports.httpOutbox, ports.httpInbox);
    initOrganisationUnitStore(ports.organisationUnitStoreOutbox, ports.organisationUnitStoreInbox);
    initPlanStore(ports.planStoreOutbox, ports.planStoreInbox);
    initAuditStore(ports.auditStoreOutbox, ports.auditStoreInbox);
    initMeasureStore(ports.measureStoreOutbox, ports.measureStoreInbox);
    // initMeasureImageEditor(ports.measureImageEditorOutbox, ports.measureImageEditorInbox);
    // initImageEditor(ports.imageEditorOutbox, ports.imageEditorInbox);
    initTenantStore(ports.tenantStoreOutbox, ports.tenantStoreInbox);
    initCheckpointDataStore(ports.checkpointDataStoreOutbox, ports.checkpointDataStoreInbox);
    initPropertiesStore(ports.propertiesStoreOutbox, ports.propertiesStoreInbox);
    // initAvatarDialog(ports.avatarDialogOutbox, ports.avatarDialogInbox);
    copyToClipboard(ports.copyToClipboardOutbox, ports.copyToClipboardInbox);
}

defineCustomElements(window)
    .then(async () => {
        await loadBaseUri();

        initCustomElement();

        const app = Elm.Main.init({
            node: document.getElementById("main"),
            flags: await initFlags(),
        });

        initPorts(app.ports);
    });
