import { Http } from "@capacitor-community/http";
import jsonData from "../../versions.json";

const apiVersion = jsonData["api-version"];

async function get(url, headerArray) {
    const headers = headerArray.reduce(
        (obj, item) => Object.assign(obj, { [item.key]: item.value }),
        { "x-api-version": `${apiVersion}` },
    );

    const options = {
        method: "GET",
        headers: headers,
        url: url,
        webFetchExtra: {
            credentials: "include",
        },
    };
    const response = await Http.request(options);

    return response;
}

async function post(url, data, headerArray) {
    const headers = headerArray.reduce(
        (obj, item) => Object.assign(obj, { [item.key]: item.value }),
        {
            "Content-Type": "application/json",
            "x-api-version": `${apiVersion}`,
        },
    );

    const options = {
        method: "POST",
        url: url,
        headers: headers,
        data: data,
        webFetchExtra: {
            credentials: "include",
        },
    };

    const response = await Http.request(options);

    return response;
}

async function sessionCheck(url) {
    const headers = {
        "x-api-version": `${apiVersion}`,
    };

    const options = {
        method: "POST",
        url: url,
        headers: headers,
        data: null,
        webFetchExtra: {
            credentials: "include",
        },
    };

    const response = await Http.request(options);

    return response;
}

async function login(url, data) {
    const headers = {
        "Content-Type": "application/json",
        "x-api-version": `${apiVersion}`,
    };

    const options = {
        method: "POST",
        url: url,
        headers: headers,
        data: data,
        webFetchExtra: {
            credentials: "include",
        },
        readTimeout: 30000,
    };

    const response = await Http.request(options);

    return response;
}

export default {
    get: get,
    post: post,
    sessionCheck: sessionCheck,
    login: login,
};
