const activeItems = {};
const lruItems = {};
const LRU_SIZE = 25;

function get(filename) {
    let item = activeItems[filename];

    if (item) {
        item.counter += 1;

        return item.objectUrl;
    }

    item = lruItems[filename];

    if (item) {
        if (item.prev) {
            lruItems[item.prev].next = item.next;
        } else {
            lruItems.first = item.next;
        }

        if (item.next) {
            lruItems[item.next].prev = item.prev;
        } else {
            lruItems.last = item.prev;
        }

        lruItems.size -= 1;
        delete lruItems[filename];

        item.prev = null;
        item.next = null;
        item.counter = 1;

        activeItems[filename] = item;

        return item.objectUrl;
    }

    return null;
}

function set(filename, objectUrl) {
    activeItems[filename] = {
        counter: 1,
        objectUrl: objectUrl,
    };
}

function revoke(filename) {
    const item = activeItems[filename];

    if (item) {
        if (item.counter <= 1) {
            item.counter = 0;

            if (lruItems.size >= LRU_SIZE) {
                const oldFirst = lruItems.first;
                const oldFirstItem = lruItems[oldFirst];

                lruItems[oldFirstItem.next].prev = null;
                lruItems.first = oldFirstItem.next;
                lruItems.size -= 1;
                delete lruItems[oldFirst];

                URL.revokeObjectURL(oldFirstItem.objectUrl);
            }

            if (lruItems.last) {
                item.prev = lruItems.last;
                item.next = null;
                lruItems[item.prev].next = filename;
                lruItems.last = filename;
                lruItems.size += 1;
                lruItems[filename] = item;
            } else {
                item.prev = null;
                item.next = null;
                lruItems.first = filename;
                lruItems.last = filename;
                lruItems.size = 1;
                lruItems[filename] = item;
            }

            delete activeItems[filename];
        } else {
            item.counter -= 1;
        }
    }
}

export default {
    get: get,
    set: set,
    revoke: revoke,
};
