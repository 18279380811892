import Db from "./db";
import Cache from "./object-url-cache";

async function getObjectUrl(filename) {
    let objectUrl = Cache.get(filename);

    if (objectUrl) {
        return objectUrl;
    }

    objectUrl = await Db.loadBlob(filename).then(URL.createObjectURL);

    if (objectUrl) {
        Cache.set(filename, objectUrl);
    }

    return objectUrl;
}

function createImage(objectUrl) {
    const image = document.createElement("img");

    image.style.height = "100%";
    image.style.width = "100%";
    image.style.objectFit = "cover";
    image.src = objectUrl;

    return image;
}

class AwImage extends HTMLElement {
    static get observedAttributes() { return ["filename"]; }

    attributeChangedCallback(name, oldFilename, newFilename) {
        if (name === "filename" && oldFilename !== newFilename) {
            this.filename = newFilename;
        }
    }

    disconnectedCallback() {
        Cache.revoke(this._filename);

        this._image = null;
    }

    set filename(filename) {
        if (!filename) {
            return;
        }

        if (this._filename) {
            Cache.revoke(this._filename);
        }

        this._filename = filename;

        getObjectUrl(filename).then((objectUrl) => {
            if (this._image) {
                this._image.src = objectUrl;
            } else {
                this._image = createImage(objectUrl);
                this.attachShadow({ mode: "closed" }).appendChild(this._image);
            }
        });
    }
}

function initCustomElement() {
    customElements.define("aw-image", AwImage);
}

export default initCustomElement;
